<template>
  <div id="addVolunteerContainer" class="relative">

    <div>

      <vs-list class="mb-5 mt-5">

        <vs-list-header icon="today" title="Datum" color="primary"></vs-list-header>
        <vs-list-item v-if="!dailyReportData.date" icon="clear"
                      title="Ni podatka"></vs-list-item>
        <vs-list-item v-if="dailyReportData.date"
                      :title="moment(dailyReportData.date).format('dddd, DD. MM. YYYY')">
        </vs-list-item>


        <vs-list-header icon="flag" title="Status" color="primary" class="mt-5"></vs-list-header>
        <vs-list-item v-if="dailyReportData.updated_at === null" icon="clear"
                      title="Dnevno poročilo še ni izpolnjeno"></vs-list-item>
        <vs-list-item v-if="dailyReportData.updated_at && !dailyReportData.submitted" icon="radio_button_unchecked"
                      title="Dnevno poročilo je začasno shranjeno"></vs-list-item>
        <vs-list-item v-if="dailyReportData.updated_at && dailyReportData.submitted" icon="check"
                      title="Dnevno poročilo je shranjeno in oddano"></vs-list-item>

        <vs-list-header icon="favorite" title="Prostovoljec" color="primary" class="mt-5"></vs-list-header>
        <vs-list-item v-if="!dailyReportData.volunteers_id" icon="clear"
                      title="Prostovoljec še ni določen"></vs-list-item>
        <vs-list-item v-if="dailyReportData.volunteers_id"
                      :title="dailyReportData.volunteer.first_name + ' ' + dailyReportData.volunteer.last_name"
                      :subtitle="dailyReportData.volunteer.notes">
          <template slot="avatar">
            <vs-avatar size="60px" :src="$globalFunctions.getUserAvatar(dailyReportData.volunteer.profile_image)"/>
          </template>
        </vs-list-item>
        <vs-list-header icon="note" title="Opombe" color="primary" class="mt-5"></vs-list-header>
        <vs-list-item v-if="!dailyReportData.notes" icon="clear"
                      title="Ni opomb"></vs-list-item>
        <vs-list-item v-if="dailyReportData.notes"
                      :title="dailyReportData.notes">
        </vs-list-item>

        <vs-list-header icon="calendar_today" title="Rezervacije" color="primary" class="mt-5"></vs-list-header>
        <ReservationsList style="position: relative; z-index: 0" v-if="dailyReportData.date"
                          :reservations-date="moment(dailyReportData.date).valueOf()"
                          :volunteer-id="dailyReportData.volunteers_id"></ReservationsList>

        <vs-list-header icon="schedule" title="Število ur prostovoljskega dela" color="primary"
                        class="mt-5"></vs-list-header>
        <vs-list-item v-if="!dailyReportData.volunteer_hours_total" icon="clear"
                      title="Ni podatka"></vs-list-item>
        <vs-list-item v-if="dailyReportData.volunteer_hours_total"
                      :title="dailyReportData.volunteer_hours_total">
        </vs-list-item>
        <vs-list-header icon="explore" title="Število prevoženih kilometrov" color="primary"
                        class="mt-5"></vs-list-header>
        <vs-list-item v-if="!dailyReportData.odometer_total" icon="clear"
                      title="Ni podatka"></vs-list-item>
        <vs-list-item v-if="dailyReportData.odometer_total"
                      :title="dailyReportData.odometer_total + ' km'"></vs-list-item>
        <vs-list-item v-if="dailyReportData.odometer_start"
                      :title="dailyReportData.odometer_start"
                      :subtitle="'Stanje na števcu na začetku dneva'"></vs-list-item>
        <vs-list-item v-if="dailyReportData.odometer_end"
                      :title="dailyReportData.odometer_end" :subtitle="'Stanje na števcu na koncu dneva'">
        </vs-list-item>
        <vs-list-header icon="restaurant" title="Kosilo" color="primary" class="mt-5"></vs-list-header>
        <vs-list-item v-if="!dailyReportData.launch" icon="clear"
                      title="Ni podatka"></vs-list-item>
        <vs-list-item v-if="dailyReportData.launch && dailyReportData.launch.name"
                      :title="dailyReportData.launch.name">
        </vs-list-item>
      </vs-list>

    </div>

    <div class="clearfix clear"></div>
    <vs-row
      vs-align="center"
      vs-type="flex" vs-justify="space-around" vs-w="12" class="mt-5">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <vs-button type="border" icon="cancel" @click="() => { $emit('cancel') }">Zapri</vs-button>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <vs-button color="primary" icon="edit" @click="editDailyReport()">Uredi</vs-button>
      </vs-col>

    </vs-row>

  </div>
</template>

<script>

import vSelect from 'vue-select'
import ReservationsList from '../../Sopotniki/reservations/ReservationsList';

export default {
  name: 'add-volunteer',

  components: {
    ReservationsList,
    'v-select': vSelect
  },
  props: {
    dailyReportData: Object
  },
  data() {
    return {
      volunteers: null,
      newVolunteer: null
    }
  },

  computed: {},

  mounted() {
    const _this = this;


  },


  methods: {
    editDailyReport() {
      const _this = this;
      _this.$router.push({name: 'Dnevno poročilo', params: {dailyReportId: _this.dailyReportData.id}});

    }


  }
}
</script>

