<template>
  <div id="dailyReportsContainer">
    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Dnevna poročila</li>
    </vs-breadcrumb>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
        <vs-button color="primary" size="small" class="mt-1 mr-2" icon="keyboard_arrow_left"
                   @click="() => {selectedDate = moment(selectedDate).subtract(1, 'M').toDate(); onDateChange();}"></vs-button>
        <datepicker :minimumView="'month'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                    :monday-first="true"
                    @input="onDateChange" v-model="selectedDate"></datepicker>
        <vs-button color="primary" size="small " class="mt-1 ml-2" icon="keyboard_arrow_right"
                   @click="() => {selectedDate = moment(selectedDate).add(1, 'M').toDate(); onDateChange();}"></vs-button>
      </vs-col>
    </vs-row>


    <div v-if="!dailyReportsData || dailyReportsData.length === 0" class="mt-2">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
          <em class="mt-5">Ni vnosov za prikaz</em>
        </vs-col>
      </vs-row>
    </div>

    <div v-if="dailyReportsData && dailyReportsData.length > 0" class="mt-2">
      <div class="vs-component vs-con-table vs-table-primary">
        <header class="header-table vs-table--header">
          <header class="vs-navbar nabarx vs-navbar-null vs-navbar-color-transparent" style="background: transparent;">
            <div class="vs-navbar--header">
              <button class="vs-navbar--btn-responsive"><span class="btn-responsive-line line--1"></span><span
                class="btn-responsive-line line--2"></span><span class="btn-responsive-line line--3"></span></button>
              <div><h3 class="vs-navbar--title py-4">
                Dnevna poročila (potni nalogi)
              </h3></div>
            </div>
            <div class="vs-con-items"></div>
          </header>
        </header>
        <div class="con-tablex vs-con-table vs-table--content">
          <div class="vs-con-tbody vs-table--tbody ">
            <table class="vs-table vs-table--tbody-table dailyReportsTable">
              <thead class="vs-table--thead">
              <tr>
                <th colspan="3" rowspan="1">
                  <div class="vs-table-text">
                    <div @click="() => { orderDirection = (orderDirection === 'asc' ? 'desc' : 'asc');  reorderByDate() }">
                    Datum <feather-icon
                    :icon       = "orderDirection === 'asc' ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    svg-classes= "w-4 h-4" />
                    </div>
                    <a class="ml-3" v-if="moment(selectedDate).format('MM/YYYY') === moment().format('MM/YYYY')" @click="jumpToToday()">Pomakni se na današnji dan</a>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="tr-values vs-table&#45;&#45;tr tr-table-state-null selected" v-for="item in dailyReportsData">
                <td>
                  <div v-if="moment(item.date).format('DD. MM. YYYY') === moment().format('DD. MM. YYYY')">
                    <vs-chip color="success" id="todayMarker">
                      <vs-avatar icon="star" color="success"/>
                      DANES
                    </vs-chip>
                  </div>
                  {{ moment(item.date).format('DD. MM. YYYY') }}
                </td>
                <td>
                  <vs-table :data="item.data" @selected="showDailyReportsDetails">
                    <template slot="thead">
                      <vs-th>
                        <!-- just a placeholder -->
                      </vs-th>
                      <vs-th>
                        Prostovoljec
                      </vs-th>
                      <vs-th>
                        Avto
                      </vs-th>
                      <vs-th>
                        Št. ur
                      </vs-th>
                      <vs-th>
                        Št. km
                      </vs-th>
                      <vs-th>
                        Oddano
                      </vs-th>
                      <vs-th>
                        Opombe
                      </vs-th>
                      <vs-th>
                        Akcije
                      </vs-th>
                    </template>

                    <template slot-scope="{data}">
                      <vs-tr :key="index" v-for="(tr, index) in data" :data="tr">

                        <vs-td :data="data[index].id" style="width: 20px;">
                          <vs-avatar size="large" v-if="item.data.length > 1" :badge="index+1"
                                     :badge-color="(data[index] && data[index].car && data[index].car.color) ? data[index].car.color : 'grey'"
                                     icon="book"/>
                          <vs-avatar size="large" v-if="item.data.length === 1" icon="book"/>
                        </vs-td>

                        <vs-td :data="data[index].volunteer" style="width: 350px;">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                                  v-if="data[index].volunteer">
                            <vs-avatar size="60px"
                                       :src="$globalFunctions.getUserAvatar(data[index].volunteer.profileImage)"/>
                            <div class="ml-2">{{
                                data[index].volunteer.firstName + ' ' +
                                data[index].volunteer.lastName
                              }}
                            </div>
                          </vs-col>
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" v-else>
                            <vs-avatar size="60px" :src="'https://via.placeholder.com/500?text=?'"/>
                            <em class="ml-2">Ni znano</em>
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].car" style="width: 250px;">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                                  v-if="data[index].car">
                            <vs-avatar size="60px" icon="directions_car"
                                       :style="'background:' + data[index].car.color"/>
                            <div class="ml-2">{{ data[index].car.name }}</div>
                          </vs-col>
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" v-else>
                            <vs-avatar size="60px" icon="directions_car"/>
                            <em class="ml-2">Ni določen</em>
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].volunteerHoursTotal" style="width: 150px;">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                                  v-if="data[index].volunteerHoursTotal">
                            <div>{{ data[index].volunteerHoursTotal }}</div>
                          </vs-col>
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" v-else>
                            <em>/</em>
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].odometerTotal" style="width: 150px;">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                                  v-if="data[index].odometerTotal">
                            <div>{{ data[index].odometerTotal }} km</div>
                          </vs-col>
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" v-else>
                            <em>/</em>
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].submitted" style="width: 150px;">

                          <vs-avatar size="60px" :icon="data[index].submitted ? 'done' : 'close'"
                                     :style="'background-color:' + (data[index].submitted ? '#28c76f' : '#c3c3c3')"/>


                        </vs-td>

                        <vs-td :data="data[index].notes" style="width: 150px;">


                          <vs-tooltip :text="data[index].notes" title="Opomba" :key="data[index].id"
                                      v-if="data[index].notes" position="left">
                            <vs-avatar icon="note"/>
                            <span>&nbsp;</span>
                          </vs-tooltip>
                          <div v-else>
                            <em>/</em>
                          </div>


                        </vs-td>

                        <vs-td :data="data[index].id" style="width: 200px;">
                          <vs-col vs-w="12">
                            <vs-button type="filled" color="primary" icon="edit" size="small" class="vs-lg-12"
                                       @click.stop="editDailyReport(data[index])">Uredi
                            </vs-button>
                            <vs-button type="filled" color="success" icon="picture_as_pdf" size="small"
                                       class="vs-lg-12 mt-2"
                                       @click.stop="downloadDailyReportPdf(data[index])">Prenesi pdf
                            </vs-button>
                            <vs-button type="filled" color="grey" icon="swap_horizontal_circle" size="small"
                                       v-if="!data[index].submitted"
                                       class="vs-lg-12 mt-2" @click.stop="replaceVolunteer(data[index])">Zamenjaj
                              prostovoljca
                            </vs-button>
<!--                            <vs-button type="filled" color="warning" icon="supervisor_account" size="small"-->
<!--                                       class="vs-lg-12 mt-2" @click.stop="downloadCovid19Statement(data[index])">-->
<!--                              COVID-19 izjava-->
<!--                            </vs-button>-->
<!--                            <vs-button type="border" color="warning" icon="approval" size="small"-->
<!--                                       class="vs-lg-12 mt-2"-->
<!--                                       @click.stop="downloadCovidTestiranjePorstovoljca(data[index])">-->
<!--                              Potrdilo za COVID-19 test-->
<!--                            </vs-button>-->
                            <!--                            test: {{moment(data[index].volunteer.birthday).format('DD. MM. YYYY')}}-->
                            <vs-button type="filled" color="danger" icon="delete_forever" size="small"
                                       v-if="!data[index].submitted"
                                       class="vs-lg-12 mt-2" @click.stop="beforeDeleteDailyReport(data[index])">Izbriši
                            </vs-button>
                          </vs-col>
                        </vs-td>


                      </vs-tr>
                    </template>
                  </vs-table>
                </td>
                <td>
                  <vs-button color="success" icon="add_circle" size="small" @click="addVolunteer(item)">Dodaj
                    prostovoljca
                  </vs-button>
                </td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">

        <DailyReport :dailyReportData="selectedDailyReport" v-if="selectedDailyReport && mode === 'dailyReport'"
                     @cancel="cancelDailyReportDetails()"/>
        <AddVolunteer :dailyReportData="selectedDailyReport" v-if="selectedDailyReport && mode === 'addVolunteer'"
                      @cancel="cancelReplaceOrAddVolunteer()" @reloadDailyReports="getDailyReports()"/>
        <ReplaceVolunteer :dailyReportData="selectedDailyReport"
                          v-if="selectedDailyReport && mode === 'replaceVolunteer'"
                          @cancel="cancelReplaceOrAddVolunteer()" @reloadDailyReports="getDailyReports()"/>

      </vs-sidebar>
    </div>

    <div id="generateCovidPdfContainer" ref="content">
      <div v-if="covid19statementReservations">
        <div class="generateCovidPdfContainer" v-for="(item, index) in covid19statementReservations">
          <div class="pdf-pagebreak-before" v-if="index > 0" style="color: white;">.</div>
          <br>
          <div class="date" style="text-align: right; float:right; width: 100%;">
            {{ item.unit.locationForContracts }}, {{ moment(item.departure_time).format('DD. MM. YYYY') }}
          </div>
          <div class="clearfix" style="float: none; clear: both;"></div>
          <br><br><br><br>
          <h3 style="text-align: center;">IZJAVA UPORABNIKA STORITVE BREZPLAČNIH PREVOZOV ZA STAREJŠE "SOPOTNIKI"</h3>
          <br><br><br>
          <div class="statement" style="text-align: justify;">
            <b>{{ item.customer.first_name + ' ' + item.customer.last_name }}</b>, <span
            v-if="item.customer.gender === 'male' || !item.customer.gender">uporabnik</span><span
            v-if="item.customer.gender === 'female'">uporabnica</span>&nbsp;
            storitve brezplčanih prevozov "Sopotniki", s podpisom izjavljam:<br><br>
            - da ne meni, ne komu od družinskih članov ni bila potrjena okužba s koronavirusom,<br>
            - da ne jaz, ne kdo od družinskih članov nismo bili v tesnem stiku z osebo s potrjeno okužbo s
            koronavirusom,<br>
            - da se niso ne pri meni, ne pri komu od družinskih članov v zadnjih 14 dneh pojavili morebitni znaki okužbe
            (povišana telesna temperatura, suh kašelj ali težko dihanje).
            <br><br>
            <em>
              Storitev brezplačnih prevozov za starejše "Sopotniki" sodi med tvegane dejavnosti, saj vključuje
              zagotavljanje storitve za rizično skupino prebivalstva - starejše nad 65 let. Med izvajanjem storitve ni
              mogoče zagotoviti ustrezne razdalje med voznikom in uporabnikom. Prav tako uporabniki obiskujejo
              zdravstvene
              ustanove in tgovine, kjer morajo tudi sami poskrbeti za lastno varnost. Kljub upoštevanju vseh navedenih
              ukrepov zato še vedno obstaja možnost prenosa okužbe z novim koronavirusom med osebama.</em>
            <br>
          </div>
          <b><span v-if="item.customer.gender === 'male' || !item.customer.gender">Uporabnik</span><span
            v-if="item.customer.gender === 'female'">Uporabnica</span> storitve s podpisom potrjujem, da se tega
            tveganja zavedam in ga sprejemam.</b>

          <br><br><br>
          <div class="signatureContainer">
            Podpis:<br><br>
            <b>{{ item.customer.first_name + ' ' + item.customer.last_name }}</b><br><br><br><br>
            ___________________________________
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import Unit from '../../components/Sopotniki/units/Unit';
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import ReplaceVolunteer from '../../components/Sopotniki/reports/ReplaceVolunteer';
import AddVolunteer from '../../components/Sopotniki/reports/AddVolunteer';
import DailyReport from '../../components/Sopotniki/reports/DailyReport';


export default {
  name: 'DailyReports',

  components: {
    AddVolunteer,
    ReplaceVolunteer,
    DailyReport,
    Datepicker
  },

  data() {
    return {
      slSI,
      dailyReportsData: null,
      unitId: null,
      sidebarActive: false,
      selectedDate: new Date(),
      covid19statementReservations: null,
      selectedDailyReport: null,
      mode: null,
      orderDirection: 'asc'
    }
  },

  computed: {},

  async mounted() {
    const _this = this;
    this.selectedDate = await this.$store.getters.getSelectedDateForMonthlyReports;
    _this.getDailyReports();
  },

  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave');
    const _this = this;
    _this.unitId = null;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    getDailyReports() {
      const _this = this;

      _this.$vs.loading();

      const month = _this.moment(_this.selectedDate).month() + 1;
      const year = _this.moment(_this.selectedDate).year();

      console.log('month', month, ', year', year);

      const url = `${Vue.prototype.$config.api.sopotnikiVPS}daily-reports?unit=${_this.$globalFunctions.getCurrentUnit().id}&startDate=${_this.moment(_this.selectedDate).startOf('month').valueOf()}&endDate=${_this.moment(_this.selectedDate).endOf('month').valueOf()}`;
      //const url = "https://sopotniki.azurewebsites.net/admin/dailyReports/unit/34/2021/2/1";

      Vue.prototype.$http.get(url)
        .then((res) => {

          _this.dailyReportsData = _.chain(res.data.data)
            .groupBy('date')
            .map((value, key) => ({date: key, data: value}))
            .value();

          _this.dailyReportsData = _.sortBy(_this.dailyReportsData, function (o) {
            return o.date;
          })

          console.log("_this.dailyReportsData", _this.dailyReportsData);

          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
          return false
        });
    },

    reorderByDate() {
      const _this = this;
      if(_this.orderDirection === 'asc') {
        _this.dailyReportsData = _.sortBy(_this.dailyReportsData, function (o) {
          return o.date;
        });
      } else {
        _this.dailyReportsData = _.sortBy(_this.dailyReportsData, function (o) {
          return o.date;
        }).reverse();
      }

    },

    showDailyReportsDetails(data) {
      const _this = this;

      console.log('HERE!', data);


      // _this.unitId = parseInt(data.id);
      _this.selectedDailyReport = data;
      _this.mode = 'dailyReport';
      _this.sidebarActive = true;
    },

    customDateFormatter(date) {
      return this.moment(date).format('MMMM, YYYY');
    },

    onDateChange() {
      this.$store.dispatch('setSelectedDateForMonthlyReports', this.selectedDate);
      this.getDailyReports();
    },

    downloadDailyReportPdf(data) {
      const _this = this;
      console.log('data', data);

      _this.$vs.loading();

      Vue.prototype.$http(`${Vue.prototype.$config.api.sopotnikiVPS}daily-volunteer-report`, {
        method: 'GET',
        responseType: 'blob',
        params: {
          unitId: data.unit.id,
          volunteerId: data.volunteer.id,
          forDate: this.moment(data.date).valueOf()
        }
      })
        .then(response => {
          //Create a Blob from the PDF Stream
          const file = new Blob(
            [response.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `Potni nalog ${_this.moment(data.date).format('DD_MM_YYYY')}_${data.id}.pdf`
          link.click()

          _this.$vs.loading.close();

        })
        .catch(error => {
          console.log(error);
        });
    },

    downloadCovidTestiranjePorstovoljca(data) {
      const _this = this;
      console.log('data', data);

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/covid-testiranje-prostovoljca/' + data.volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `potrdilo_covid_19_test_${data.volunteer.first_name}_${data.volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });
    },

    downloadCovid19Statement(data) {
      const _this = this;
      console.log('data', data);

      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      _this.$vs.loading();

      Vue.prototype.$http(`${Vue.prototype.$config.api.productionOld}reservationsday/unit/${data.unit.id}/${_this.moment(data.date).valueOf()}`)
        .then(res => {
          _this.covid19statementReservations = _.filter(res.data.data, {volunteer_id: data.volunteer.id});
          console.log('_this.covid19statementReservations', _this.covid19statementReservations);

          //
          setTimeout(
            () => {
              const pdfElement = document.querySelector('#generateCovidPdfContainer')
              const pdfRes = this.extractCSS(pdfElement)

              const html = htmlToPdfmake(_this.$refs.content.innerHTML);
              const docDefinition = {
                content: [html],
                styles: pdfRes.stylesheet,
                pageBreakBefore(currentNode) {
                  return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
                }
              };

              console.log(pdfMake.createPdf(docDefinition));

              const pdfDocGenerator = pdfMake.createPdf(docDefinition).download(`izjava_${_this.moment(data.date).format('DD_MM_YYYY')}_${data.id}`);

              console.log(pdfDocGenerator);

              console.log('pdfDocGenerator', pdfDocGenerator);
              _this.swapBackIds(pdfRes.elements)
              _this.$vs.loading.close();
              _this.covid19statementReservations = null;
            }, 500);

        })
        .catch(error => {
          console.log(error);
        });


    },

    beforeDeleteDailyReport(data) {
      const _this = this;


      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Potrditev izbire',
        text: `Ali si prepričan, da želiš izbrisati dnevni potni nalog za dan ${_this.moment(data.date).format('dddd, DD. MM. YYYY')}? Po potrditvi bo potni nalog trajno izbrisan.`,
        accept: () => {
          _this.$vs.loading();
          _this.deleteDailyReport(data);
        }
      })

    },

    deleteDailyReport(data) {
      const _this = this;

      //Vue.prototype.$http.delete(`${Vue.prototype.$config.api.productionOld}dailyReport/${data.id}`)
      Vue.prototype.$http.delete(`${Vue.prototype.$config.api.sopotnikiVPS}daily-reports/${data.id}`)
        .then(res => {

          _this.$vs.loading.close();
          _this.getDailyReports();

        })
        .catch(error => {
          console.log(error);
        });
    },

    editDailyReport(dailyReportData) {
      const _this = this;

      _this.$router.push({name: 'Dnevno poročilo', params: {dailyReportId: dailyReportData.id}})
    },

    replaceVolunteer(data) {
      const _this = this;

      _this.selectedDailyReport = data;
      _this.mode = 'replaceVolunteer';
      _this.sidebarActive = true;
    },

    addVolunteer(data) {
      const _this = this;

      _this.selectedDailyReport = data;
      _this.mode = 'addVolunteer';
      _this.sidebarActive = true;
    },

    cancelReplaceOrAddVolunteer() {
      const _this = this;

      _this.selectedDailyReport = null;
      _this.mode = null;
      _this.sidebarActive = false;
    },

    cancelDailyReportDetails() {
      const _this = this;

      _this.selectedDailyReport = null;
      _this.mode = null;
      _this.sidebarActive = false;
    },


    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },

    flatten(arr) {
      const _this = this;

      return arr.reduce(function (flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) ? _this.flatten(toFlatten) : toFlatten);
      }, []);
    },

    recursiveExtract(element) {
      const id = this.uuidv4()
      const oldId = element.id

      const computed = window.getComputedStyle(element)
      const style = computed.cssText

      // Now that we get the style, we can swap the id
      element.setAttribute('id', id)

      // The children are not a real array but a NodeList, we need to convert them
      // so we can map over them easily
      const children = Array.prototype.slice.call(element.children)
      return [{id, style, oldId}].concat(children.map(this.recursiveExtract))
    },

    extractCSS(element) {
      if (!element) {
        return {elements: [], stylesheet: ''}
      }

      const raw = this.recursiveExtract(element)
      const flat = this.flatten(raw)

      return {
        elements: flat,
        stylesheet: flat.reduce(function (acc, cur) {
          const style = `#${cur.id} {\n${cur.style}\n}\n\n`
          return acc + style
        }, '')
      }
    },

    swapBackIds(elements) {
      elements.forEach(function (e) {
        const element = document.getElementById(e.id)
        element.setAttribute('id', e.oldId)
      })
    },

    jumpToToday() {
      console.log("this.$refs[\"todayMarker\"]", this.$refs["todayMarker"]);
      //this.$refs["todayMarker"].scrollIntoView({ behavior: "smooth" })
      let element = document.getElementById("todayMarker");
      let top = element.offsetTop;
      window.scrollTo(  {top,
        left: 0,
        behavior: 'smooth'});
    }


  }
}
</script>

